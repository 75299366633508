import React from 'react';
import { FormattedMessage } from 'react-intl';

import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import Paragraph from '../Atoms/Paragraph';
import Box from '../Atoms/Box';
import CustomLink from '../Atoms/CustomLink';
import theme from '../../styles/theme';
import { pageUrls } from '../../config/pageSetting';
import Heading from '../Atoms/Heading';

class SiteMap extends React.PureComponent {
  render() {
    const { footer } = this.props;
    return (
      <Row py={[3, 3, 3, 0]} mobile>
        {footer.map(category => (
          <Column key={category.title}>
            <Box>
              <Heading h={4} color={theme.color.white} fontSize={[0]} pb={[2, 2, 2, 3]}>
                {category.title || ''}
              </Heading>
              {category.submenu.map(subItem => (
                <CustomLink key={subItem.path} to={`/${subItem.path}`}>
                  <Paragraph pb={[1, 1, 1, 2]} fontSize={0}>{subItem.label || ''}</Paragraph>
                </CustomLink>
              ))}
            </Box>
          </Column>
        ))}
        <Column>
          <Box>
            <Heading h={4} color={theme.color.white} fontSize={[0]} pb={[2, 2, 2, 3]}>
              <FormattedMessage id="footer.enquiry" defaultMessage="Planning an event?" />
            </Heading>
            <CustomLink to={pageUrls.enquiry.url} fontSize={0} onClick={this.closeMenu}>
              {pageUrls.enquiry.title}
            </CustomLink>
          </Box>
        </Column>
      </Row>
    );
  }
}

export default SiteMap;
