import 'perfect-scrollbar/css/perfect-scrollbar.css';
import '../styles/app.scss';
import '../styles/fullpage.scss';

import React from 'react';
import Helmet from 'react-helmet';
import { addLocaleData, IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import theme from '../styles/theme';

import { useSiteMetadata } from '../utilis/getMetaData';
import { langList, DEFAULT_LANG } from '../config/langSetting';

const getIntlLocale = locale => require(`react-intl/locale-data/${locale}`);
const getMessages = locale => require(`../lang/${locale}.json`);

const messagesList = {};

langList.forEach((lang) => {
  try {
    addLocaleData(getIntlLocale(lang));
    messagesList[lang] = getMessages(lang);
  } catch (e) {
    console.log(e);
  }
});

const TemplateWrapper = ({
  children, pageContext, location, ...rest
}) => {
  const { meta } = useSiteMetadata();
  const { title, description } = meta;

  const lang = pageContext.lang || DEFAULT_LANG;

  let purePath = `${location.pathname}/`.replace('//', '/');

  langList.forEach((lang) => {
    purePath = purePath.replace(`/${lang}/`, '/');
  });

  return (
    <IntlProvider key={lang} locale={lang} messages={messagesList[lang]} initialNow={Date.now()}>
      <ThemeProvider theme={theme}>
        <div>
          <Helmet>
            <html lang={lang} />
            <title>{title}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            <meta name="description" content={description} />
            <link rel="apple-touch-icon" sizes="180x180" href="/img/apple-touch-icon.png" />
            <link rel="shortcut icon" type="image/x-icon" href="/img/favicon.ico" />
            <link rel="icon" type="image/png" href="/img/favicon-32x32.png" sizes="32x32" />
            <link rel="icon" type="image/png" href="/img/favicon-16x16.png" sizes="16x16" />
            <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#ff4400" />
            <meta name="theme-color" content="#fff" />
            <meta property="og:type" content="business.business" />
            <meta property="og:title" content={title} />
            <meta property="og:url" content="/" />
            <meta property="og:image" content="/img/og-image.jpg" />
            <script type="text/javascript" src="//downloads.mailchimp.com/js/signup-forms/popup/unique-methods/embed.js" data-dojo-config="usePlainJson: true, isDebug: false" />
          </Helmet>
          <Navbar lang={lang} pathname={purePath} />
          <div className={purePath === '/' ? 'home' : ''} id="__layout">{children}</div>
          {purePath !== '/' && purePath !== '/events/' && purePath !== '/restaurants/' && <Footer pathname={purePath} />}
        </div>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default TemplateWrapper;
