import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import values from 'lodash/values';

import { FormattedMessage } from 'react-intl';
import Box from './Atoms/Box';
import FlexBox from './Atoms/FlexBox';
import CustomLink from './Atoms/CustomLink';

import { pageUrls } from '../config/pageSetting';
import { DEFAULT_LANG } from '../config/langSetting';

import Logo from '../assets/icons/logo-color.svg';
import LogoWhite from '../assets/icons/logo-white.svg';
import LogoSmall from '../assets/icons/logo-color-small.svg';
import LogoWhiteSmall from '../assets/icons/logo-white-small.svg';
import Paragraph from './Atoms/Paragraph';
import theme from '../styles/theme';

const LogoLink = styled(Link)`
  padding-left: 4px;
  ${space}
`;

const EnquiryButton = styled(FlexBox)`
  width: 180px;
  transition: border-color 100ms ease-in-out;
  border: 1px solid ${props => props.theme.color.white};
  &:hover {
    border: 1px solid ${props => props.theme.color.gold};
  }
`;

const NavbarWrapper = styled.nav`
  && {
    display: flex;
    width: 100vw;
    z-index: 1002;
    transition: transform 400ms cubic-bezier(0.15, 0.69, 0.32, 1);
    background-color: rgba(0, 0, 0, 0.2);
    transform: translate3d(0, 0, 0);
    ${props => props.hideBackground && css`
      background-color: transparent;
    `}
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
    &.hidden {
      transition: transform 400ms cubic-bezier(0.54, 0.03, 1, 0.88);
      transform: translate3d(0, -200px, 0);
    }
  }
  ${space}
`;

const StyledBrand = styled.div`
  ${space}
`;

@observer
class Navbar extends React.Component {
  lastLocation = 0;

  currDir = false; // true = up, false = down

  threshold = 200;

  scrollDistance = 0;

  // side menu
  @observable active = false;

  // top menu
  @observable hideNav = 0;

  toggleMenu = () => {
    // toggle the active boolean in the state
    this.active = !this.active;
    const g = document.getElementById('___gatsby');
    const l = document.getElementById('__layout');
    if (g && l) {
      g.className = this.active ? 'show-menu' : '';
      l.addEventListener('click', this.closeMenu);
      l.addEventListener('touch', this.closeMenu);
    }
  };

  closeMenu = () => {
    // toggle the active boolean in the state
    if (this.active) {
      this.active = false;
    }
    const g = document.getElementById('___gatsby');
    const l = document.getElementById('__layout');
    if (g && l) {
      g.className = '';
      l.removeEventListener('click', this.closeMenu);
      l.removeEventListener('touch', this.closeMenu);
    }
  };

  handleScroll = (e) => {
    // -------- always close menu when user start to scroll
    // this.closeMenu();

    if (this.active) {
      return;
    }

    // -------- determent user is scrolling up or down
    const currTop = typeof window !== 'undefined' ? window.pageYOffset : false;
    const nextDir = currTop < this.lastLocation;

    // reset distance if direction is not the same
    this.scrollDistance = nextDir !== this.currDir ? 0 : this.scrollDistance;

    // update direction
    this.currDir = nextDir;

    // add new distance
    this.scrollDistance += this.lastLocation - currTop;

    // go up and reach
    if (this.scrollDistance >= this.threshold) {
      this.hideNav = false;
    }

    // go down and reach
    if (this.scrollDistance <= -this.threshold) {
      // down to threshold
      this.hideNav = true;
    }

    this.lastLocation = currTop;
  };

  handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  componentDidMount() {
    const { pathname } = this.props;

    if (typeof window !== 'undefined' && pathname !== '/') {
      window.addEventListener('scroll', this.handleScroll, true);
    }
  }

  componentWillUnmount() {
    const { pathname } = this.props;

    if (typeof window !== 'undefined' && pathname !== '/') {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  render() {
    const { lang, pathname } = this.props;
    const pathPrefix = lang === DEFAULT_LANG ? '/' : `/${lang}/`;

    const invertedPath = ['/contact/', '/restaurants/'];
    // const inverted = invertedPath.indexOf(pathname) !== -1;
    const inverted = true;

    return (
      <>
        <Box className="menu-container">
          <div className="menu-wrap" onClick={this.handleStopPropagation}>
            <nav className="menu">
              <div className="icon-list">
                {values(pageUrls).filter(v => !v.hidden).map(({ url, title }) => (
                  <CustomLink key={url} to={url} onClick={this.closeMenu}>
                    <span>{title}</span>
                  </CustomLink>
                ))}
                <Box pt={[3, 3, 6]}>
                  <Paragraph color={theme.color.white} fontSize={[0, 0, 20]}>
                    <FormattedMessage id="menu.enquiry.info" defaultMessage="Planning an event?" />
                  </Paragraph>
                  <CustomLink to={pageUrls.enquiry.url} onClick={this.closeMenu}>
                    <EnquiryButton border={`1px solid ${theme.color.white}`} p={2}>
                      <span>{pageUrls.enquiry.title}</span>
                    </EnquiryButton>
                  </CustomLink>
                </Box>
              </div>
            </nav>
          </div>
        </Box>
        <NavbarWrapper
          className={`navbar is-fixed-top is-transparent ${this.hideNav ? 'hidden' : ''}`}
          role="navigation"
          aria-label="main-navigation"
          hideBackground={pathname === '/'}
          pt={0}
          pb={0}
          px={[2, 2, 3]}
        >
          <StyledBrand className="navbar-brand" px={2}>
            <LogoLink to={`${pathPrefix}`} className="navbar-item" onClick={this.closeMenu} p={0}>
              <FlexBox
                display={['none', 'none', 'none', 'block']}
                width={[300]}
                height="auto"
                lineHeight={0}
              >
                {pathname !== '/' && inverted && <LogoWhite />}
                {pathname !== '/' && !inverted && <Logo />}
              </FlexBox>
              {pathname !== '/' && (
              <FlexBox
                display={['block', 'block', 'block', 'none']}
                width={[40, 40, 50]}
                height="auto"
                lineHeight={0}
              >
                <LogoWhiteSmall />
              </FlexBox>
              )}
            </LogoLink>
            {/* Hamburger menu */}
          </StyledBrand>
          <Box
            className={`navbar-burger burger ${this.active ? 'is-active' : ''} ${inverted ? 'is-inverted' : ''}`}
            data-target="navMenu"
            onClick={this.toggleMenu}
            display="block"
            mr={[2, 2, 2, 1]}
          >
            <span />
            <span />
            <span />
          </Box>
        </NavbarWrapper>
      </>
    );
  }
}

export default Navbar;
