import { FormattedMessage } from 'react-intl';
import React from 'react';

export const pageUrls = {
  home: {
    url: '/',
    title: <FormattedMessage id="pageUrl:home" defaultMessage="Home" />,
  },
  restaurants: {
    url: '/restaurants',
    title: <FormattedMessage id="pageUrl:restaurants" defaultMessage="Restaurants" />,
  },
  events: {
    url: '/events',
    title: <FormattedMessage id="pageUrl:events" defaultMessage="Events & Catering" />,
  },
  ourStory: {
    url: '/ourstory',
    title: <FormattedMessage id="pageUrl:ourStory" defaultMessage="Our Story" />,
  },
  career: {
    url: '/careers',
    title: <FormattedMessage id="pageUrl:career" defaultMessage="Careers" />,
  },
  contact: {
    url: '/contact',
    title: <FormattedMessage id="pageUrl:contact" defaultMessage="Contact Us" />,
  },
  enquiry: {
    url: '/enquiry',
    hidden: true,
    title: <FormattedMessage id="pageUrl:enquiry" defaultMessage="Make an enquiry" />,
  },
};
