// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-event-page-js": () => import("/builds/imagiro-group/info-web-content/core/src/templates/event-page.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-index-page-js": () => import("/builds/imagiro-group/info-web-content/core/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-story-page-js": () => import("/builds/imagiro-group/info-web-content/core/src/templates/story-page.js" /* webpackChunkName: "component---src-templates-story-page-js" */),
  "component---src-templates-restaurants-individual-page-js": () => import("/builds/imagiro-group/info-web-content/core/src/templates/restaurants-individual-page.js" /* webpackChunkName: "component---src-templates-restaurants-individual-page-js" */),
  "component---src-templates-restaurants-page-js": () => import("/builds/imagiro-group/info-web-content/core/src/templates/restaurants-page.js" /* webpackChunkName: "component---src-templates-restaurants-page-js" */),
  "component---src-templates-enquiry-page-js": () => import("/builds/imagiro-group/info-web-content/core/src/templates/enquiry-page.js" /* webpackChunkName: "component---src-templates-enquiry-page-js" */),
  "component---src-pages-404-js": () => import("/builds/imagiro-group/info-web-content/core/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-index-js": () => import("/builds/imagiro-group/info-web-content/core/src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-contact-index-js": () => import("/builds/imagiro-group/info-web-content/core/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */)
}

