import { action, observable, computed } from 'mobx';

export default class UIStore {
  @observable lang = 'en';

  cms;

  constructor({cms = false}) {
    this.cms = cms;
  }

}
