const theme = {
  space: [0, 4, 8, 16, 24, 32, 40, 64, 128, 256, 512],
  breakpoints: ['576px', '768px', '992px', '1200px'],
  fontSizes: [16, 18, 32, 40],
  color: {
    gold: '#c99d66',
    deepBlack: '#000000',
    black: '#151427',
    gray: '#73727d',
    lightGray: '#f8f8f6',
    white: '#ffffff',
    red: '#eb5757',
  },
};

export default theme;
