import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import { space } from 'styled-system';

import { LANG_ZH } from '../config/langSetting';
import Container from './Atoms/Container';
import Row from './Atoms/Row';
import Column from './Atoms/Column';
import Box from './Atoms/Box';
import SiteMap from './Footer/SiteMap';
import theme from '../styles/theme';
import SubscribeForm from './Footer/SubscribeForm';
import LogoLarge from '../assets/icons/logo-large.svg';
import Paragraph from './Atoms/Paragraph';

const StyledFooter = styled.footer`
  && {
    z-index: 10;
    border-top: 4px solid ${props => props.theme.color.gold};
    position: relative;
    background-color: ${props => props.theme.color.black};
    ${space}
  }
`;

export class FooterUI extends React.Component {
  render() {
    const { data, pathname } = this.props;
    const { footer, copyright } = data.markdownRemark.frontmatter;

    return (
      <StyledFooter className="section fp-auto-height footer" py={6}>
        {/* <Box */}
        {/*  posAb */}
        {/*  left={0} */}
        {/*  top={['50%', '50%', '50%', 0]} */}
        {/*  width={['100%', '100%', '100%', '50%']} */}
        {/*  height={['0', '0', '0', '100%']} */}
        {/*  background={theme.color.black} */}
        {/* /> */}
        <Container textAlign={['center', 'center', 'left']}>
          <Row multi>
            <Column col="is-12" pt={6}>
              <Box width={200} m={['auto', 'auto', 0]}>
                <LogoLarge />
              </Box>
            </Column>
            <Column col="is-12 is-6-desktop">
              <SiteMap footer={footer} />
            </Column>
            <Column col="is-12 is-6-desktop" pr={[6, 7, 3]} pl={[3, 3, 3, 7]}>
              <SubscribeForm copyright={copyright} />
            </Column>
          </Row>
          <Row>
            <Column>
              <Box width="100%" pt={3}>
                <Paragraph pb={0} fontSize={[0]} color={theme.color.gray}>
                  {copyright}
                </Paragraph>
              </Box>
            </Column>
          </Row>
        </Container>
      </StyledFooter>
    );
  }
}

export const footerQuery = graphql`
  query SiteMapPageQuery {
    markdownRemark(frontmatter: { component: { eq: "footer" } }) {
      frontmatter {
        copyright
        footer {
          title
          submenu {
            label
            path
          }
        }
      }
    }
  }
`;

class Footer extends React.Component {
  render() {
    return <StaticQuery query={footerQuery} render={data => <FooterUI data={data} />} />;
  }
}

export default Footer;
