import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import styled from 'styled-components';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import jsonp from 'jsonp';

import FlexBox from '../Atoms/FlexBox';
import Heading from '../Atoms/Heading';
import Paragraph from '../Atoms/Paragraph';
import Box from '../Atoms/Box';
import Button from '../Atoms/Button';
import theme from '../../styles/theme';

import Facebook from '../../assets/icons/facebook.svg';
import IG from '../../assets/icons/ig.svg';

const StyledHeading = styled(Heading)`
  //text-transform: uppercase;
  span {
    display: inline-block;
  }
  span:first-child {
  }
`;

const StyledForm = styled.form`
  width: 100%;
  input {
    background-color: ${props => props.theme.color.black};
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    font-family: 'Karla', sans-serif;
    -webkit-text-fill-color: ${props => props.theme.color.white} !important;
    color: ${props => props.theme.color.white} !important;
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.color.black} inset !important;
    background-color: ${props => props.theme.color.black} !important;
  }
`;

@observer
class SubscribeForm extends Component {
  @observable submitted = false;

  submitForm = async (values, actions) => {
    jsonp(
      `${'https://imagiroconcepts.us17.list-manage.com/subscribe/post-json?u=d1c65d0c4becae18f4013777e&id=4b73a9f6c8&EMAIL='}${values.EMAIL}`,
      {
        param: 'c',
      }, (err, data) => {
        actions.setSubmitting(false);
        if (data.result === 'error') {
          actions.setErrors({ common: data.msg });
        } else {
          actions.setStatus({ submitted: 'Thank you for subscribing to our updates!' });
        }
      },
    );
  }

  render() {
    const { copyright } = this.props;

    return (
      <FlexBox color={theme.color.white}>
        <StyledHeading h={4} width="100%" fontSize={0} pb={[3, 3, 3, 4]}>
          <span>
            <FormattedHTMLMessage id="footer:subscribeForm.title.1" defaultMessage="Keep up to" />
          </span>
            &nbsp;
          <span>
            <FormattedHTMLMessage
              id="footer:subscribeForm.title.2"
              defaultMessage="date with us"
            />
          </span>
        </StyledHeading>
        <Paragraph width="100%" fontSize={14}>
          <FormattedHTMLMessage
            id="footer:subscribeForm.content"
            defaultMessage="Sign up to be the first to receive special news and event updates from Imagiro Concepts."
          />
        </Paragraph>
        <Formik
          initialValues={{
            EMAIL: '',
          }}
          validate={(values, props) => {
            const errors = {};
            return errors;
          }}
          onSubmit={async (values, actions) => {
            this.submitForm(values, actions);
          }}
          render={({
            values,
            errors,
            status,
            touched,
            handleSubmit,
            setStatus,
            setFieldValue,
            isSubmitting,
            isValid,
            setSubmitting,
            ...actions
          }) => (
            <StyledForm
              onSubmit={handleSubmit}
              novalidate
            >
              <FlexBox width="100%" pb={[2, 2, 2, 3]}>
                <Box width={['70%', '70%', '70%', '70%']} pr={2}>
                  <input
                    required
                    style={{ color: theme.color.white }}
                    className="input"
                    type="email"
                    name="EMAIL"
                    placeholder="Email Address"
                    defaultValue=""
                    onChange={actions.handleChange}
                    onBlur={actions.handleBlur}
                  />
                </Box>
                <Box width={['30%', '30%', '30%', '30%']}>
                  <Button
                    fontSize={14}
                    type="submit"
                    width="100%"
                    text="subscribe"
                    disabled={isSubmitting || !isValid}
                  />
                </Box>
              </FlexBox>
              {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
              <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                <input type="text" name="b_d1c65d0c4becae18f4013777e_4b73a9f6c8" tabIndex="-1" defaultValue="" />
              </div>
              {errors.common && (
              <Box id="mce-responses" pb={[2, 2, 2, 3]}>
                <Paragraph fontSize={0} color={theme.color.red}>
                  <span dangerouslySetInnerHTML={{ __html: errors.common }} />
                </Paragraph>
              </Box>
              )}
              {status && status.submitted && (
              <Box id="mce-responses" pb={[2, 2, 2, 3]}>
                <Paragraph fontSize={0} color={theme.color.gold}>
                  {status.submitted}
                </Paragraph>
              </Box>
              )}
            </StyledForm>
          )}
        />
      </FlexBox>
    );
  }
}

export default SubscribeForm;
