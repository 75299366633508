import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import startsWith from 'lodash/startsWith';
import { fontSize, width } from 'styled-system';

const StyledExternalLink = styled.a`
  color: ${props => props.color || props.theme.color.white};
  transition: color 100ms ease-in-out;
  &:hover, &:active {
    color: ${props => props.hoverColor || props.theme.color.gold};
  }
  ${fontSize}
  ${width}
`;

const StyledInternalLink = StyledExternalLink.withComponent(GatsbyLink);

const CustomLink = ({
  children, to, openNewTab, activeClassName, hoverColor, ...other
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = !startsWith(to, 'https://') && !startsWith(to, 'http://') && !startsWith(to, '/static/');

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <StyledInternalLink fontSize={[0, 0, 1]} to={`${to}`} activeClassName={activeClassName} {...other}>
        {children}
      </StyledInternalLink>
    );
  }
  return (
    <StyledExternalLink fontSize={[0, 0, 1]} href={to} target={openNewTab ? '_blank' : '_self'} {...other}>
      {children}
    </StyledExternalLink>
  );
};

export default CustomLink;
